<template>
    <div class="calendarContainer">
        <div class="second">
            <div class="button-group">
                <button
                    :class="['btn btn-white', { active: calendarType === 'date' }]"
                    style="width: 50px"
                    @click="setCalendarType('date')"
                >
                    일
                </button>
                <button
                    :class="['btn btn-white', { active: calendarType === 'week' }]"
                    style="width: 50px"
                    @click="setCalendarType('week')"
                >
                    주
                </button>
                <button
                    :class="['btn btn-white', { active: calendarType === 'month' }]"
                    style="width: 50px"
                    @click="setCalendarType('month')"
                >
                    월
                </button>
                <button
                    :class="['btn btn-white', { active: calendarType === 'year' }]"
                    style="width: 90px"
                    @click="setCalendarType('year')"
                >
                    연도
                </button>
            </div>
            <div>
                <h3>기본 캘린더</h3>
                <date-picker
                    v-model="baseDayTime"
                    :type="calendarType"
                    range
                    valueType="YYYY-MM-DD"
                    lang="ko"
                    placeholder="기본 기간 선택"
                    :formatter="formatWeekRange"
                ></date-picker>
            </div>
            <div>
                <h3>비교 캘린더</h3>
                <date-picker
                    v-model="compareDayTime"
                    :type="calendarType"
                    range
                    valueType="YYYY-MM-DD"
                    lang="ko"
                    placeholder="비교 기간 선택"
                    :shortcuts="shortcuts"
                    :formatter="formatWeekRange"
                ></date-picker>
            </div>
        </div>
        <div class="calendarTable">
            <b-table-simple bordered class="text-center">
                <b-thead>
                    <b-tr>
                        <b-th>기본 캘린더</b-th>
                        <b-th>비교 캘린더</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr>
                        <b-td>{{ baseDayTime ? baseDayTime : "선택되지 않음" }}</b-td>
                        <b-td>{{ compareDayTime ? compareDayTime : "선택되지 않음" }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <calendar-component />
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import CalendarComponent from "./CalendarComponent.vue";

dayjs.extend(isoWeek);

export default {
    components: {
        DatePicker,
        CalendarComponent,
    },
    data() {
        return {
            baseDayTime: ["2023-11-23", "2023-12-30"],
            compareDayTime: null,
            calendarType: "date",
            shortcuts: [
                { text: "전월", onClick: () => this.applyPreviousMonth() },
                { text: "전년", onClick: () => this.applyPreviousYear() },
            ],
        };
    },
    methods: {
        setCalendarType(type) {
            this.calendarType = type;
        },
        // 전월 버튼 클릭 시 호출되는 메서드
        applyPreviousMonth() {
            this.setComparePeriod(-1, "month");
        },
        // 전년 버튼 클릭 시 호출되는 메서드
        applyPreviousYear() {
            this.setComparePeriod(-1, "year");
        },
        // 전달 또는 전년의 동일한 주차와 요일로 비교 캘린더 설정
        setComparePeriod(amount, unit) {
            if (!this.baseDayTime || this.baseDayTime.length !== 2) return;

            const baseStart = dayjs(this.baseDayTime[0]);
            const baseEnd = dayjs(this.baseDayTime[1]);

            const startOfMonth = baseStart.startOf("month");
            const startWeekOfMonth = baseStart.isoWeek() - startOfMonth.isoWeek() + 1;
            const endWeekOfMonth = baseEnd.isoWeek() - baseEnd.startOf("month").isoWeek() + 1;
            const startWeekdayIndex = baseStart.day();
            const endWeekdayIndex = baseEnd.day();

            const compareMonthStart = baseStart.add(amount, unit).startOf("month");
            const compareStart = compareMonthStart
                .add(startWeekOfMonth - 1, "week")
                .startOf("week")
                .add(startWeekdayIndex, "day")
                .format("YYYY-MM-DD");
            const compareEnd = compareMonthStart
                .add(endWeekOfMonth - 1, "week")
                .startOf("week")
                .add(endWeekdayIndex, "day")
                .format("YYYY-MM-DD");

            this.compareDayTime = [compareStart, compareEnd];
        },
        // 주 단위 선택 시 표시 포맷 설정
        formatWeekRange(date) {
            if (this.calendarType === "week" && Array.isArray(date) && date.length === 2) {
                const startOfWeek = dayjs(date[0])
                    .startOf("week")
                    .format("YYYY-MM-DD");
                const endOfWeek = dayjs(date[0])
                    .endOf("week")
                    .format("YYYY-MM-DD");
                return `${startOfWeek} ~ ${endOfWeek}`;
            }
            return dayjs(date).format("YYYY-MM-DD");
        },
    },
};
</script>

<style scoped>
.calendarContainer {
    background-color: white;
    height: 50vh;
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-group {
    display: flex;
    margin: 10px;
    position: relative;
    background-color: #cbd2d9;
    padding: 10px;
    border-radius: 5px;
}

.second {
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendarTable {
    display: flex;
    justify-content: center;
    width: 80%;
}
</style>
