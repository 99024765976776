<template>
  <div>
    <calendar-component />
    <!-- primevue DataTable Test, made by woonsik -->
    <!-- @column-reorder="onColReorder" -->
    <DataTable
      ref="DataTable"
      :value="tableDatas"
      editMode="row"
      dataKey="vin"
      :editingRows.sync="editingRows"
      :reorderableColumns="true"
      @row-edit-save="onRowEditSave"
      @row-reorder="onRowReorder"
      showGridlines
      :selection.sync="selected"
      responsiveLayout="scroll"
      style="width: 100%; padding: 2rem"
    >
      <template #header>
        <div style="text-align: left">
          <button
            type="button"
            class="btn btn-primary"
            style="margin-right: 1rem"
            @click="excelExport($event)"
          >
            Excel Export
          </button>
          <button
            type="button"
            class="btn btn-warning"
            @click="() => $refs.inputCoyDataModal.show()"
          >
            Add Copy Data
          </button>
        </div>
      </template>
      <Column
        :rowReorder="true"
        :headerStyle="{ width: '2%' }"
        :reorderableColumn="false"
        :styles="{ width: '2%' }"
      />

      <Column
        selectionMode="multiple"
        :styless="{ width: '2%' }"
        :reorderableColumn="false"
        :exportable="false"
        :styles="{ width: '2%' }"
      ></Column>

      <Column
        v-for="col in columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :reorderableColumn="false"
        :styles="{ width: '20%' }"
      >
        <template #editor="slotProps">
          <InputText
            v-model="slotProps.data[slotProps.column.field]"
            autofocus
          />
        </template>
      </Column>

      <Column
        :rowEditor="true"
        :styles="{ width: '10%', 'min-width': '8rem' }"
        :reorderableColumn="false"
        :bodyStyle="{ 'text-align': 'center' }"
      >
      </Column>
    </DataTable>

    <b-modal ref="inputCoyDataModal" centered>
      <template #modal-header>
        <h5 class="m-0">붙여넣기</h5>
      </template>

      <p>엑셀에서 복사한 데이터를 아래에 붙여넣고 추가 버튼을 누르세요.</p>
      <textarea
        ref="clipboard"
        class="form-control"
        @paste="onPaste"
        placeholder="여기에 Excel 데이터를 붙여넣으세요"
        style="width: 100%; height: 300px; resize: none"
      ></textarea>

      <template #modal-footer>
        <div style="display: flex; flex: 1">
          <button
            type="button"
            class="btn btn-primary"
            style="flex: 1; margin-right: 0.5rem"
            @click="addCopyData"
          >
            추가
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            style="flex: 1; margin-left: 0.5rem"
            @click="() => $refs.inputCoyDataModal.hide()"
          >
            닫기
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CalendarComponent from "./Calendar.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import ColumnGroup from 'primevue/columngroup';
import InputText from "primevue/inputtext";

export default {
  components: {
    CalendarComponent,

    DataTable,
    Column,
    // ColumnGroup,
    InputText,
  },
  data() {
    return {
      // primevue DataTable Test, made by woonsik
      tableDatas: [
        { brand: "Volkswagen", year: 2012, color: "Orange", vin: "dsad231ff" },
        { brand: "Audi", year: 2011, color: "Black", vin: "gwregre345" },
        { brand: "Renault", year: 2005, color: "Gray", vin: "h354htr" },
        { brand: "BMW", year: 2003, color: "Blue", vin: "j6w54qgh" },
        { brand: "Mercedes", year: 1995, color: "Orange", vin: "hrtwy34" },
        { brand: "Volvo", year: 2005, color: "Black", vin: "jejtyj" },
        { brand: "Honda", year: 2012, color: "Yellow", vin: "g43gr" },
        { brand: "Jaguar", year: 2013, color: "Orange", vin: "greg34" },
        { brand: "Ford", year: 2000, color: "Black", vin: "h54hw5" },
        { brand: "Fiat", year: 2013, color: "Red", vin: "245t2s" },
      ],
      editingRows: [],
      columns: [
        { field: "vin", header: "Vin" },
        { field: "brand", header: "Brand" },
        { field: "year", header: "Year" },
        { field: "color", header: "Color" },
      ],
      copyData: null,
      selected: null,
    };
  },

  methods: {
    // primevue DataTable Test, made by woonsik
    onRowEditSave(event) {
      let { newData, index } = event;

      this.tableDatas[index] = newData;
    },
    onRowReorder(event) {
      this.tableDatas = event.value;
    },
    // onColReorder() {
    //     this.$toast.add({severity:'success', summary: 'Column Reordered', life: 3000});
    // },
    excelExport() {
      this.$refs["DataTable"].exportCSV();
    },
    onPaste(event) {
      // event.preventDefault();
      // // 클립보드 데이터를 텍스트 형식으로 가져오기
      const clipboardData = event.clipboardData.getData("text");
      console.log(clipboardData);

      // // 각 행을 구분하여 배열로 나누기
      this.copyData = clipboardData.trim().split("\n");
      console.log(this.copyData);
    },
    async addCopyData() {
      try {
        this.copyData.forEach((row) => {
          const cells = row.split("\t");
          console.log("cells", cells);
          const rowData = {};

          cells.forEach((cell, index) => {
            rowData[this.columns[index].field] = cell;
            // console.log("rowData[this.columns[index].field]", rowData[this.columns[index].field])
          });
          console.log("rowData", rowData);
          this.tableDatas.push(rowData);
        });
        await this.alertNoti("테이블에 복사한 데이터를 추가하였습니다.");
        await this.$refs["inputCoyDataModal"].hide();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
